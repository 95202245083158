import React from "react"
import { FaqData, FaqConfig } from "../../config/config-data"


const Faq = ({ GradientAnimation }) => (

  <section id={FaqConfig.section_header}>
    {FaqConfig.section_header &&
    <div id="faq_1662792611912" className={`Heading_Text ${!GradientAnimation ? "Heading_Gradient" : ""}`}>{FaqConfig.section_header}</div>
    }
    {FaqConfig.section_sub_header &&
    <div id="faq_1662792614216" className="Normal_Text" style={{paddingBottom:"var(--Text_Size_Five)"}}>{FaqConfig.section_sub_header}</div>
    }
    {FaqData.map(({ faq_question, faq_answer }) => (
      <details id={"faq_"+faq_question} className="Collapse_Card" key={faq_question}>
        <summary className="Collapse_Title">{faq_question}</summary>
        <div>{faq_answer}</div>
      </details>
    ))}
  </section>
);

export default Faq